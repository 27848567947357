import CampoTexto from '../../../components/campos_dados/campo_texto';
import CampoSelect from '../../../components/campos_dados/campo_select';

class CCodigoIdentificacao extends CampoTexto {
  constructor() {
    super({
      nome: 'cod_identificacao',
      label: 'Código de identificação',
      icone: 'fas fa-file-alt',
      valor: '',
      regras: [],
    });
  }
}

class CDescricao extends CampoTexto {
  constructor() {
    super({
      nome: 'descricao',
      label: 'Descrição',
      icone: 'fas fa-stream',
      valor: '',
      regras: [],
    });
  }
}

class CModal extends CampoSelect {
  constructor() {
    super({
      nome: 'modal',
      label: 'Modal',
      icone: 'fas fa-th-list',
      valor: '',
      itens: [
        { codigo: '1', nome: 'Vidro' },
        { codigo: '2', nome: 'Inflamável' },
        { codigo: '3', nome: 'Químico' },
        { codigo: '4', nome: 'Volumoso' },
        { codigo: '5', nome: 'Pesado' },
      ],
      regras: [],
    });
  }
}

class CMarca extends CampoTexto {
  constructor() {
    super({
      nome: 'marca',
      label: 'Marca',
      icone: 'fas fa-copyright',
      valor: '',
      regras: []
    });
  }
}

class CGrupo extends CampoTexto {
  constructor() {
    super({
      nome: 'grupo',
      label: 'Grupo',
      icone: 'fas fa-layer-group',
      valor: '',
      regras: []
    });
  }
}

class CDoca extends CampoSelect {
  constructor() {
    super({
      nome: 'doca',
      label: 'Doca',
      icone: 'fas fa-folder',
      valor: '',
      itens: ['Doca 1', 'Doca 2', 'Doca 3', 'Doca 4', 'Doca 5'],
      regras: []
    });
  }
}

export const camposBusca = {
  codIdentificacao: new CCodigoIdentificacao(),
  descricao: new CDescricao(),
  modal: new CModal(),
  marca: new CMarca(),
  grupo: new CGrupo(),
  doca: new CDoca(),
};
